import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {Project} from "../../models/Project";
import {projectAPI} from "../../services/ProjectsService";
import {Image} from "../../models/Image";
import {Copy} from "../../models/Copy";

const initialState = {
    list: Array<Image>,
};

export const imagesSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
        updateImageCopy: (state, action: PayloadAction<Copy>) => {
            console.log(state);
            console.log(action);
        }
    },
});

export const {updateImageCopy} = imagesSlice.actions;
export const selectImage = (state: RootState) => {

};

export class Copy {
    #copyId: bigint | null;
    #imageId: bigint;
    #domain: string;
    #domainId: bigint;
    #page: string;
    #url: string;
    #comment: string;
    #code: string;
    #stage: string;
    #abuse: string;
    #created_at: string;

    constructor(
        copyId: bigint | null,
        domain: string,
        domainId: bigint,
        page: string,
        url: string,
        comment: string,
        code: string,
        stage: string,
        abuse: string,
        created_at: string,
        imageId: bigint,
    ) {
        this.#copyId = copyId;
        this.#domain = domain;
        this.#domainId = domainId;
        this.#page = page;
        this.#url = url;
        this.#comment = comment;
        this.#code = code;
        this.#stage = stage;
        this.#abuse = abuse;
        this.#created_at = created_at;
        this.#imageId = imageId;
    }

    get imageId(): bigint {
        return this.#imageId;
    }

    set imageId(value: bigint) {
        this.#imageId = value;
    }
    get created_at(): string {
        return this.#created_at;
    }

    set created_at(value: string) {
        this.#created_at = value;
    }

    get url(): string {
        return this.#url;
    }

    set url(value: string) {
        this.#url = value;
    }
    get page(): string {
        return this.#page;
    }
    get domainId(): bigint {
        return this.#domainId;
    }

    set domainId(value: bigint) {
        this.#domainId = value;
    }
    get domain(): string {
        return this.#domain;
    }

    set domain(value: string) {
        this.#domain = value;
    }
    get copyId(): bigint | null {
        return this.#copyId;
    }

    set copyId(value: bigint | null) {
        this.#copyId = value;
    }

    get comment(): string {
        return this.#comment;
    }

    set comment(value: string) {
        this.#comment = value;
    }
    get code(): string {
        return this.#code;
    }

    set code(value: string) {
        this.#code = value;
    }
    get stage(): string {
        return this.#stage;
    }

    set stage(value: string) {
        this.#stage = value;
    }
    get abuse(): string {
        return this.#abuse;
    }

    set abuse(value: string) {
        this.#abuse = value;
    }
}

import {ForwardedRef, forwardRef, Ref, useImperativeHandle, useRef, useState} from "react";
import {BaseWindow, BaseWindowRef} from "../../features/BaseWindow";
import {Form, Input, InputRef} from "antd";
import {Source} from "../../models/Source";
import {useCreateSourceMutation, useUpdateSourceMutation} from "../../services/SourcesService";

export type EditSourceWindowPropsType = {
};

export type EditSourceWindowRef = {
    open: (source?: Source | undefined) => void,
    close: () => void,
}

const sourceInitialValue = new Source(null, '');

export const EditSourceWindow = forwardRef<EditSourceWindowRef, EditSourceWindowPropsType>((props: EditSourceWindowPropsType, ref: ForwardedRef<EditSourceWindowRef>) => {
    const [form] = Form.useForm();
    const [createSource] = useCreateSourceMutation();
    const [updateSource] = useUpdateSourceMutation();
    const baseRef = useRef<BaseWindowRef>(null);
    const [source, setSource] = useState(sourceInitialValue);

    useImperativeHandle<EditSourceWindowRef, EditSourceWindowRef>(ref, () => ({
        open: (source: Source | undefined) => {
            setSource(source || sourceInitialValue);
            form.setFieldsValue({
                url: source?.url,
            })
            baseRef.current?.open();
        },
        close: () => {
            baseRef.current?.close();
        }
    }));

    const handleFinish = (values: any): void => {
        const newSource: Source = new Source(source.sourceId || null, values.url);
        source.sourceId ? updateSource(newSource) : createSource(newSource);
        baseRef.current?.close();
    }

    const onUrlChange = (e: any) => {
        const editedSource = new Source(source.sourceId, e.target.value);
        setSource(editedSource)
    }

    return (
        <BaseWindow
            title={source.sourceId ? 'Редактирование источника' : 'Новый источник'}
            ref={baseRef}
            onSubmit={() => form.submit()}
            onFinish={handleFinish}
        >
            <Form
                form={form}
                scrollToFirstError
                >
                <Form.Item
                    label="URL"
                    name="url"
                    rules={[{required: true}]}
                >
                    <Input onChange={onUrlChange}/>
                </Form.Item>
            </Form>
        </BaseWindow>
    );
})

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import env from "react-dotenv";
import {Image} from "../models/Image";
import {PageDto} from "../dto/PageDto";
import {Pagination} from "../models/Pagination";
import {Project} from "../models/Project";
import {ImageDto} from "../dto/ImageDto";
import {Copy} from "../models/Copy";


export class FetchImagesParams {
    pagination: Pagination;
    project: Project;

    constructor(pagination: Pagination, project: Project) {
        this.pagination = pagination;
        this.project = project;
    }
}

export const imageAPI = createApi({
    reducerPath: 'imageAPI',
    baseQuery: fetchBaseQuery({
            baseUrl: env.API_URL
        }
    ),
    tagTypes: ['Image'],
    endpoints: (build) => ({
        fetchImages: build.query<PageDto<Image>, FetchImagesParams>({
            query: (fetchParams) => {
                const params: any = {
                    size: fetchParams.pagination.size || 10,
                };

                if (fetchParams.pagination.page > 1) {
                    params.page = fetchParams.pagination.page - 1;
                }
                params.project_id = fetchParams.project.projectId;
                return {
                    url: '/images',
                    params,
                }
            },
            providesTags: result => ['Image'],
            transformResponse(response: PageDto<ImageDto>, meta, arg): PageDto<Image> {
                const transformedResult = structuredClone(response);

                transformedResult.content = [];

                response.content?.map(image => {
                    const copies: Copy[] = [];

                    if (image.copies) {
                        image.copies?.map(dto => {
                            const copy: Copy = new Copy(
                                dto.copy_id || null,
                                '',
                                dto.domain_id || BigInt(1),
                                dto.page || '',
                                dto.url || '',
                                dto.comment || '',
                                dto.code || '',
                                dto.stage || '',
                                dto.abuse || '',
                                dto.created_at || '',
                                dto.image_id || BigInt(0),
                            );

                            copies.push(copy);
                        });
                    }

                    const transformedImage = new Image(
                        image.image_id || null,
                        image.url,
                        image.project_id,
                        image.post_id || BigInt(1),
                        image.page || '',
                        image.modified || '',
                        image.subscribe || '',
                        image.description || '',
                        image.created_at || '',
                        image.updated_at || '',
                        copies,
                    );
                    transformedResult.content?.push(transformedImage);
                })

                return transformedResult;
            }
        }),
        searchCopies: build.mutation<number, number>({
            query: (id) => ({
                url: `search/${id}`,
            }),
            invalidatesTags: result => ['Image'],
        }),
        getAttachedImages: build.mutation<void, Project>({
            query: (project: Project) => ({
                url: 'getAttachedImages',
                params: {
                    project_id: project.projectId,
                },
            }),
            invalidatesTags: result => ['Image'],
        }),
        updateCopy: build.mutation<Copy, Copy>({
            query: (copy: Copy) => ({
                url: '/copy/' + copy.copyId,
                method: 'PUT',
                body: {
                    copy_id: copy.copyId,
                    stage: copy.stage,
                    comment: copy.comment,
                    code: copy.code,
                    abuse: copy.abuse,
                    url: copy.url,
                    page: copy.page,
                    domain_id: copy.domainId,
                    created_at: copy.created_at,
                },
            }),
            invalidatesTags: result => ['Image'],
        }),
        export: build.mutation<void, Project>({
            query: (project) => {
                return {
                    url: '/export',
                    params: {
                        project_id: project.projectId,
                    }
                }
            },
        }),
    }),
});

export const {
    useFetchImagesQuery,
    useSearchCopiesMutation,
    useLazyFetchImagesQuery,
    useGetAttachedImagesMutation,
    useUpdateCopyMutation,
    useExportMutation,
} = imageAPI;
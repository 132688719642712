import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {IProject} from "../models/IProject";
import env from "react-dotenv";
import {PageDto} from "../dto/PageDto";
import {Pagination} from "../models/Pagination";
import {Project} from "../models/Project";
import {ProjectDto} from "../dto/ProjectDto";
import {Source} from "../models/Source";

export const projectAPI = createApi({
    reducerPath: 'projectAPI',
    baseQuery: fetchBaseQuery({
            baseUrl: env.API_URL
        },
    ),
    tagTypes: ['Project'],
    endpoints: (build) => ({
        fetchAllProjects: build.query<Project[], void>({
            query: () => {
                return '/projects/all';
            },
            providesTags: result => ['Project'],
            transformResponse(response: ProjectDto[], meta, arg): Project[] {
                const transformedResult: Project[] = [];

                response.map(project => {
                    const transformedProject = new Project(project.project_id || null, project.title || '', project.source_id || BigInt(0));
                    transformedResult.push(transformedProject);
                })

                return transformedResult;
            }
        }),
        fetchProjects: build.query<PageDto<Project>, Pagination>({
            query: (pagination) => {
                const params: any = {
                    size: pagination.size || 10,
                };

                if (pagination.page > 1) {
                    params.page = pagination.page - 1;
                }
                return {
                    url: '/projects',
                    params,
                }
            },
            providesTags: result => ['Project'],
            transformResponse(response: PageDto<ProjectDto>, meta, arg): PageDto<Project> {
                const transformedResult = new PageDto<Project>();

                transformedResult.content = [];

                response.content?.map(project => {
                    const transformedProject = new Project(project.project_id || null, project.title || '', project.source_id || BigInt(0));
                    transformedResult.content?.push(transformedProject);
                })

                return transformedResult;
            }
        }),
        updateProject: build.mutation<Project, Project>({
            query: (project: Project) => ({
                url: '/projects',
                method: 'PUT',
                body: {
                    project_id: project.projectId,
                    title: project.title,
                    source_id: project.source_id,
                },
            }),
            invalidatesTags: ['Project'],
        }),
        createProject: build.mutation<Project, Project>({
            query: (project) => ({
                url: '/projects',
                method: 'POST',
                body: {
                    title: project.title,
                    source_id: project.source_id,
                },
            }),
            invalidatesTags: ['Project'],
        }),
        deleteProject: build.mutation<Project, Project>({
            query: (project) => ({
                url: `/projects/delete/${project.projectId}`,
                method: 'POST',
            }),
            invalidatesTags: ['Project'],
        }),
    }),
});

export const {
    useFetchProjectsQuery,
    useLazyFetchProjectsQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useFetchAllProjectsQuery,
    useLazyFetchAllProjectsQuery
} = projectAPI;
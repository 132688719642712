interface PageSort {
    empty: boolean,
    unsorted: boolean,
    sorted: boolean,
}

export class PageDto<T> {
    public content: T[] | undefined;
    public last: boolean | undefined;
    public totalPages: number | undefined;
    public totalElements: number | undefined;
    public first: true | undefined;
    public size: number | undefined;
    public numberOfElements: boolean | undefined;
    public empty: boolean | undefined;
    public sort: PageSort | undefined;
}
import {Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import type { UploadProps} from "antd";

const { Dragger } = Upload;

export const UploadPanel = () => {

    const props: UploadProps = {
        name: 'file',
        action: 'http://localhost:3001/api/upload',
    };

    return (
        <Dragger {...props}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">нажмите или перетащите сюда текстовый файл</p>
            <p className="ant-upload-hint">
                ВНИМАНИЕ! Все изображения и найденные копии будут удалены. Сделайте экспорт перед закгрузкой.
            </p>
        </Dragger>
    );
}
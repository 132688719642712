import {Form, Modal} from "antd";
import {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {WindowState} from "../../models/WindowState";
import {JSX} from "react/jsx-dev-runtime";

export type BaseWindowPropsType = {
    title: string,
    children: JSX.Element,
    onSubmit: () => void,
    onFinish: (values: any) => void,
    width?: string | number,
    height?: string | number,
};

export type BaseWindowRef = {
    open: () => void,
    close: () => void,
}

const windowInitialState = {
    isOpened: false,
} as WindowState;

export const BaseWindow = forwardRef<BaseWindowRef, BaseWindowPropsType>((props: BaseWindowPropsType, ref: ForwardedRef<BaseWindowRef>) => {
    const [windowState, setWindowsState] = useState(windowInitialState)

    useImperativeHandle<BaseWindowRef, BaseWindowRef>(ref, () => ({
        open: () => {
            show();
        },
        close: () => {
            hide();
        },
    }));

    useEffect(() => {
        hide();
    }, [])

    const show = () => {
        updateWindowState({
            isOpened: true,
        })
    }

    const hide = () => {
        updateWindowState({
            isOpened: false,
        })
    }

    const updateWindowState = (data: any) => {
        setWindowsState({
            ...windowState,
            ...data,
        })
    }

    const handleOk = () => {
        props.onSubmit();
    }

    return (
        <Form.Provider
            onFormFinish={(name, {forms, values}) => {
                props.onFinish(values);
            }}
        >
            <Modal
                title={props.title}
                open={windowState.isOpened}
                onCancel={hide}
                onOk={handleOk}
                width={props.width}
            >
                {props.children}
            </Modal>
        </Form.Provider>
    )
})

import App from "../../App";
import {Row, Col, Popconfirm, Table, Space, Button, Tag} from "antd";
import {
    projectAPI,
    useFetchAllProjectsQuery,
    useLazyFetchAllProjectsQuery
} from "../../services/ProjectsService";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import { TablePaginationConfig } from 'antd/es/table';
import {useRef, useState} from "react";
import {Pagination} from "../../models/Pagination";
import {EditProjectWindow, EditProjectWindowRef} from "./EditProjectWindow";
import {Project} from "../../models/Project";
import ButtonGroup from "antd/es/button/button-group";
import {useFetchSourcesQuery} from "../../services/SourcesService";
import {Source} from "../../models/Source";
import {useStore} from "react-redux";
import {useRequiredTypedSelector} from "../../hooks/useRequiredSelector";
import {selectCurrentProject, setCurrentProject} from "../../store/reducers/ProjectSlice";

export const Projects = () => {
    const editProjectWindowRef = useRef<EditProjectWindowRef>(null)
    const {data: projectsData, error, isLoading } = useFetchAllProjectsQuery()
    const {data: sourcesData} = useFetchSourcesQuery({} as Pagination)
    const [trigger] = useLazyFetchAllProjectsQuery()
    // const [createProject, {isLoading : isCreating}] = useCreateProjectMutation();
    const [deleteProject, {isLoading: isDeleting}] = projectAPI.useDeleteProjectMutation();
    const currentProject = useRequiredTypedSelector(selectCurrentProject).project;
    const { dispatch } = useStore();

    const onDeleteProject = (project: Project) => {
        deleteProject(project);

        if (currentProject.projectId === project.projectId) {
            dispatch(setCurrentProject(projectsData ? projectsData[0] : {} as Project));
        }
    }

    const handleEdit = (project: Project) => {
        editProjectWindowRef.current?.open(project);
    }

    const getSourceUrl = (sourceId: bigint): string => {
        const source = sourcesData?.content?.find((source: Source) => source.sourceId === sourceId)
        return String(source?.url);
    }

    const columns = [{
        title: 'Название',
        dataIndex: 'title',
        key: 'title',
    }, {
        title: 'Источник',
        dataIndex: 'source_id',
        key: 'source_id',
        render: (sourceId: bigint, row: Project) =>
                <Tag>
                    {getSourceUrl(sourceId)}
                </Tag>
    }, {
        title: 'Действия',
        dataIndex: 'projectId',
        render: (projectId: number, row: Project) =>
            <ButtonGroup>
                <Button
                    onClick={() => handleEdit(row)}
                >
                    <EditOutlined />
                </Button>
                <Popconfirm
                    title="Удаление проекта"
                    description="При удалении проекта будут удалены все изображения и найденные копии. Удаляем?"
                    onConfirm={() => onDeleteProject(row)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button><DeleteOutlined /></Button>
                </Popconfirm>
            </ButtonGroup>
    }];

    const onAddProject = async () => {
        editProjectWindowRef.current?.open();
    }

    const paginationConfig: TablePaginationConfig = {
        total: projectsData?.length,
        showTotal: (total: number) => 'Всего записей: ' + total,
        showSizeChanger: true, //(() => (projectsData?.totalPages || 0) > 1)(),
        pageSizeOptions: ['1', '10', '25', '50', '100'],
        onChange: (page, pageSize) => {
            trigger();
        }
    }

    return (
        <App>
            <Row>
                <Col>
                <Space align='start'>
                    <Button type="primary" onClick={() => onAddProject()}>+ Создать</Button>
                </Space>
                </Col>
            </Row>
            <Table
                dataSource={projectsData || []}
                columns={columns}
                loading={isLoading || isDeleting}
                rowKey='projectId'
                pagination={paginationConfig}
            />
            <EditProjectWindow
                ref={editProjectWindowRef}
            />
        </App>
    );
}
import {
    createBrowserRouter,
    RouteObject, RouterProvider,
} from "react-router-dom";
import {Images, Upload, Projects, Sources} from "./pages";
import {Descriptions} from "antd";

const routes: RouteObject[] = [{
    path: "/",
    element: <Projects />,
}, {
    path: "/sources",
    element: <Sources />,
}, {
    path: "/images",
    element: <Images />,
}, {
    path: "/upload",
    element: <Upload />
}, {
    path: "/violators",
    element: <Upload />
}];

export const MyRouterProvider = (children: any) => {
    return (
        <RouterProvider router={createBrowserRouter(routes)}/>
    );
}
import {ForwardedRef, forwardRef, useImperativeHandle, useRef, useState} from "react";
import {BaseWindow, BaseWindowRef} from "../../features/BaseWindow";
import {Form, Input, Select} from "antd";
import {Project} from "../../models/Project";
import {useCreateProjectMutation, useUpdateProjectMutation} from "../../services/ProjectsService";
import {Source} from "../../models/Source";
import {useFetchSourcesQuery} from "../../services/SourcesService";
import {Pagination} from "../../models/Pagination";

export type EditProjectWindowPropsType = {
};

export type EditProjectWindowRef = {
    open: (project?: Project | undefined) => void,
    close: () => void,
}

const projectInitialValue = new Project(null, '', BigInt(0));

export const EditProjectWindow = forwardRef<EditProjectWindowRef, EditProjectWindowPropsType>((props: EditProjectWindowPropsType, ref: ForwardedRef<EditProjectWindowRef>) => {
    const [form] = Form.useForm();
    const [createProject] = useCreateProjectMutation();
    const [updateProject] = useUpdateProjectMutation();
    const {data: sourcesData } = useFetchSourcesQuery({} as Pagination);
    const baseRef = useRef<BaseWindowRef>(null);
    const [project, setProject] = useState(projectInitialValue);

    useImperativeHandle<EditProjectWindowRef, EditProjectWindowRef>(ref, () => ({
        open: (project: Project | undefined) => {
            form.setFieldsValue({
                name: project?.title,
                source_id: project?.source_id,
            })
            setProject(project || projectInitialValue);
            baseRef.current?.open();
        },
        close: () => {
            baseRef.current?.close();
        }
    }));

    const handleFinish = (values: any): void => {
        const newProject: Project = new Project(project.projectId, values.name, values.source_id)
        project.projectId ? updateProject(newProject) : createProject(newProject);
        baseRef.current?.close();
    }

    const onTitlehange = (e: any) => {
        const editedProject = new Project(project.projectId, e.target.value, project.source_id);
        setProject(editedProject)
    }

    const onSourceChange = (e: bigint) => {
        const editedProject = new Project(project.projectId, project.title, e);
        setProject(editedProject)
    }

    return (
        <BaseWindow
            title={project.projectId ? 'Редактирование проекта' : 'Новый проект'}
            ref={baseRef}
            onSubmit={() => form.submit()}
            onFinish={handleFinish}
        >
            <Form
                form={form}
                scrollToFirstError
                >
                <Form.Item
                    label="Название"
                    name="name"
                    rules={[{required: true}]}
                >
                    <Input onChange={onTitlehange}/>
                </Form.Item>
                <Form.Item
                    label="Источник"
                    name="source_id"
                    rules={[{required: true}]}
                    >
                    <Select
                        options={sourcesData?.content?.map(source => {
                            return { value: source.sourceId, label: source.url };
                        })}
                        onChange={onSourceChange}
                    />
                </Form.Item>
            </Form>
        </BaseWindow>
    );
})

import {Copy} from "./Copy";

export class Image {
    #imageId: bigint | null;
    #url: string | undefined;
    #projectId: bigint | undefined;
    #page: string;
    #postId: bigint;
    #modified: string;
    #subscribe: string;
    #description: string;
    #createdAt: string;
    #updatedAt: string;
    #copies: Copy[];

    /**
     *
     * @param imageId
     * @param url
     * @param copies
     * @param projectId
     * @param postId
     * @param page
     * @param modified
     * @param subscribe
     * @param description
     * @param createdAt
     * @param updatedAt
     * @param copies
     */
    constructor(
        imageId: bigint | null,
        url: string | undefined,
        projectId: bigint | undefined,
        postId: bigint,
        page: string,
        modified: string,
        subscribe: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        copies: Copy[],
    ) {
        this.#imageId = imageId;
        this.#url = url;
        this.#copies = copies;
        this.#page = page;
        this.#postId = postId;
        this.#modified = modified;
        this.#subscribe = subscribe;
        this.#description = description;
        this.#createdAt = createdAt;
        this.#updatedAt = updatedAt;
        this.#copies = copies;
    }

    get page(): string {
        return this.#page;
    }

    set page(value: string) {
        this.#page = value;
    }

    get postId(): bigint {
        return this.#postId;
    }

    set postId(value: bigint) {
        this.#postId = value;
    }

    get updatedAt(): string {
        return this.#updatedAt;
    }

    set updatedAt(value: string) {
        this.#updatedAt = value;
    }

    get createdAt(): string {
        return this.#createdAt;
    }

    set createdAt(value: string) {
        this.#createdAt = value;
    }

    get description(): string {
        return this.#description;
    }

    set description(value: string) {
        this.#description = value;
    }

    get subscribe(): string {
        return this.#subscribe;
    }

    set subscribe(value: string) {
        this.#subscribe = value;
    }

    get modified(): string {
        return this.#modified;
    }

    set modified(value: string) {
        this.#modified = value;
    }

    get copies(): Copy[] {
        return this.#copies;
    }

    set copies(value: Copy[]) {
        this.#copies = value;
    }
    get url(): string | undefined {
        return this.#url;
    }

    set url(value: string | undefined) {
        this.#url = value;
    }

    get imageId(): bigint | null {
        return this.#imageId;
    }

    set imageId(value: bigint | null) {
        this.#imageId = value;
    }
    get projectId(): bigint | undefined {
        return this.#projectId;
    }

    set projectId(value: bigint | undefined) {
        this.#projectId = value;
    }
}
export class Project {
    private _projectId: bigint | null;
    private _title: string;
    private _source_id: bigint;

    public constructor(projectId: bigint | null, title: string, source_id: bigint) {
        this._projectId = projectId;
        this._title = title;
        this._source_id = source_id;
    }

    get projectId(): bigint | null {
        return this._projectId;
    }

    set projectId(projectId: bigint | null) {
        this._projectId = projectId;
    }
    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get source_id(): bigint {
        return this._source_id;
    }

    set source_id(value: bigint) {
        this._source_id = value;
    }
}
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import env from "react-dotenv";
import {PageDto} from "../dto/PageDto";
import {Pagination} from "../models/Pagination";
import {Source} from "../models/Source";
import {SourceDto} from "../dto/SourceDto";

export const sourceAPI = createApi({
    reducerPath: 'sourceAPI',
    baseQuery: fetchBaseQuery({
            baseUrl: env.API_URL
        },
    ),
    tagTypes: ['Source'],
    endpoints: (build) => ({
        fetchSources: build.query<PageDto<Source>, Pagination>({
            query: (pagination) => {
                const params: any = {
                    size: pagination.size || 10,
                };

                if (pagination.page > 1) {
                    params.page = pagination.page - 1;
                }
                return {
                    url: '/sources',
                    params,
                }
            },
            providesTags: result => ['Source'],
            transformResponse(response: PageDto<SourceDto>, meta, arg): PageDto<Source> {
                const transformedResult = new PageDto<Source>();

                transformedResult.content = [];

                response.content?.map(source => {
                    const transformedSource = new Source(source.source_id || null, source.url || '');
                    transformedResult.content?.push(transformedSource);
                })

                return transformedResult;
            }
        }),
        updateSource: build.mutation<Source, Source>({
            query: (source: Source) => ({
                url: '/sources',
                method: 'PUT',
                body: {
                    source_id: source.sourceId,
                    url: source.url,
                },
            }),
            invalidatesTags: ['Source'],
        }),
        createSource: build.mutation<Source, Source>({
            query: (source: Source) => ({
                url: '/sources',
                method: 'POST',
                body: {
                    url: source.url,
                },
            }),
            invalidatesTags: ['Source'],
        }),
        deleteSource: build.mutation<Source, Source>({
            query: (source: Source) => ({
                url: `/sources/${source.sourceId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Source'],
        }),
    }),
});

export const { useCreateSourceMutation, useDeleteSourceMutation, useUpdateSourceMutation, useFetchSourcesQuery, useLazyFetchSourcesQuery } = sourceAPI;
export class Source {
    private _sourceId: bigint | null;
    private _url: string;

    public constructor(sourceId: bigint | null, url: string) {
        this._sourceId = sourceId;
        this._url = url;
    }

    get sourceId(): bigint | null {
        return this._sourceId;
    }

    set sourceId(sourceId: bigint | null) {
        this._sourceId = sourceId;
    }
    get url(): string {
        return this._url;
    }

    set url(url: string) {
        this._url = url;
    }
}
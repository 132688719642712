import {Breadcrumb, Layout, Menu, theme, Row, Divider, Avatar} from "antd";
import 'antd/dist/reset.css';
import './App.css';
import React, {useState} from "react";
import { MenuProps, Typography } from 'antd';
import {
  CloudDownloadOutlined,
  FileImageFilled, ProjectFilled, UploadOutlined, WarningFilled,
} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {Toolbar} from "./components/Toolbar";
import {useFetchAllProjectsQuery} from "./services/ProjectsService";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Проекты', '/', <ProjectFilled />),
  getItem('Источники', '/sources', <CloudDownloadOutlined />),
  getItem('Изображения', '/images', <FileImageFilled />),
  getItem('Нарушители', '/violators', <WarningFilled />),
  getItem('Загрузка файла', '/upload', <UploadOutlined />),
];

const { Header, Footer, Content, Sider} = Layout;

function App(props: any) {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [selectedKey] = useState(window.location.pathname);

  const {
      token: { colorBgContainer },
  } = theme.useToken();

  const handleSelect = (item: MenuItem) => {
    const to: string = item?.key?.toString() || "/";
    navigate(to);
  }
  return (
      <div className="App">
        <Layout style={{minHeight: '100vh'}} className="layout">
          <Sider style={{paddingTop: 20}} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <Avatar src='/favicon.png'/><Typography.Title hidden={collapsed} level={3} style={{color: '#fff'}}>Copies Hunter</Typography.Title>
            <Divider/>
            <Menu
                defaultSelectedKeys={['/']}
                selectedKeys={[selectedKey]}
                theme="dark"
                mode="inline"
                items={items}
                onSelect={(item) => handleSelect(item)}
            />
          </Sider>
          <Layout>
            <Header style={{display: 'block', padding: 0, background: colorBgContainer}}>
              <Toolbar/>
            </Header>
            <Content style={{margin: '0 16px'}}>
              <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Главная</Breadcrumb.Item>
                <Breadcrumb.Item>Профиль</Breadcrumb.Item>
              </Breadcrumb>
              <div style={{ padding: 24, height: '100%', background: colorBgContainer }}>
                {props.children}
              </div>
            </Content>
            <Footer>
              SoftLab ©2023 <a href="https://soft-lab.net">soft-lab.net</a>
            </Footer>
          </Layout>
        </Layout>
      </div>
  );
}

export default App;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {Project} from "../../models/Project";
import {projectAPI} from "../../services/ProjectsService";

const initialState = {
    project: {} as Project,
};

export const projectSlice = createSlice({
    name: 'currentProject',
    initialState,
    reducers: {
        setCurrentProject: (state, action: PayloadAction<Project>) => {
            state.project = action.payload;
        }
    },
});

export const { setCurrentProject } = projectSlice.actions;
export const selectCurrentProject = (state: RootState) => state.currentProject;

import {ForwardedRef, forwardRef, Ref, useImperativeHandle, useRef, useState} from "react";
import {BaseWindow, BaseWindowRef} from "../../features/BaseWindow";
import {Form, Input} from "antd";
import {useCreateSourceMutation, useUpdateSourceMutation} from "../../services/SourcesService";
import {Copy} from "../../models/Copy";
import {useUpdateCopyMutation} from "../../services/ImageService";
import {useStore} from "react-redux";
import {updateImageCopy} from "../../store/reducers/ImagesSlice";
const {TextArea} = Input;

export type EditCopyWindowPropsType = {
};

export type EditCopyWindowRef = {
    open: (copy?: Copy | undefined, callback?: (copy: Copy) => void ) => void,
    close: () => void,
}

export const EditCopyWindow = forwardRef<EditCopyWindowRef, EditCopyWindowPropsType>((props: EditCopyWindowPropsType, ref: ForwardedRef<EditCopyWindowRef>) => {
    const [form] = Form.useForm();
    const [updateCopy] = useUpdateCopyMutation();
    const baseRef = useRef<BaseWindowRef>(null);
    const [copy, setCopy] = useState({} as Copy);
    const {dispatch} = useStore();

    useImperativeHandle<EditCopyWindowRef, EditCopyWindowRef>(ref, () => ({
        open: (copy: Copy | undefined) => {
            setCopy(copy || {} as Copy);
            form.setFieldsValue({
                code: copy?.code,
                abuse: copy?.abuse,
                comment: copy?.comment,
                stage: copy?.stage,
            })
            baseRef.current?.open();
        },
        close: () => {
            baseRef.current?.close();
        }
    }));

    const handleFinish = (values: any): void => {
        copy.code = values.code;
        copy.abuse = values.abuse;
        copy.comment = values.comment;
        copy.stage = values.stage;

        updateCopy(copy);

        dispatch(updateImageCopy(copy));

        baseRef.current?.close();
    }

    const onlChangeField = (e: any) => {
    }

    return (
        <BaseWindow
            title={copy.copyId ? 'Редактирование копии' : 'Новая копия'}
            ref={baseRef}
            onSubmit={() => form.submit()}
            onFinish={handleFinish}
            width={800}
        >
            <Form
                form={form}
                scrollToFirstError
                >
                <Form.Item
                    label="Шифр"
                    name="code"
                >
                    <Input onChange={onlChangeField}/>
                </Form.Item>
                <Form.Item
                    label="Этап"
                    name="stage"
                >
                    <Input onChange={onlChangeField}/>
                </Form.Item>
                <Form.Item
                    label="Комментарий"
                    name="comment"
                >
                    <TextArea showCount
                              maxLength={4096}
                              style={{ height: 400, marginBottom: 24 }}
                              onChange={onlChangeField}
                    />
                </Form.Item>
                <Form.Item
                    label="Претензия"
                    name="abuse"
                >
                    <Input onChange={onlChangeField}/>
                </Form.Item>
            </Form>
        </BaseWindow>
    );
})

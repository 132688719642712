import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {imageAPI} from "../services/ImageService";
import {projectAPI} from "../services/ProjectsService";
import {projectSlice} from "./reducers/ProjectSlice";
import {sourceAPI} from "../services/SourcesService";
import {imagesSlice} from "./reducers/ImagesSlice";

const rootReducer = combineReducers({
    [imageAPI.reducerPath]: imageAPI.reducer,
    [projectAPI.reducerPath]: projectAPI.reducer,
    [sourceAPI.reducerPath]: sourceAPI.reducer,
    currentProject: projectSlice.reducer,
    images: imagesSlice.reducer,
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(imageAPI.middleware, projectAPI.middleware, sourceAPI.middleware)
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
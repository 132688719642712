import App from "../../App";
import {Row, Col, Popconfirm, Table, Space, Button} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import { TablePaginationConfig } from 'antd/es/table';
import {useRef, useState} from "react";
import {Pagination} from "../../models/Pagination";
import {EditSourceWindow, EditSourceWindowRef} from "./EditSourceWindow";
import ButtonGroup from "antd/es/button/button-group";
import {useDeleteSourceMutation, useFetchSourcesQuery, useLazyFetchSourcesQuery} from "../../services/SourcesService";
import {Source} from "../../models/Source";

export const Sources = () => {
    const editSourceWindowRef = useRef<EditSourceWindowRef>(null)
    const {data: sourcesData, error, isLoading } = useFetchSourcesQuery({} as Pagination)
    const [trigger] = useLazyFetchSourcesQuery()
    const [deleteSource, {isLoading: isDeleting}] = useDeleteSourceMutation();

    const onDeleteSource = (source: Source) => {
        deleteSource(source);
    }

    const handleEdit = (source: Source) => {
        editSourceWindowRef.current?.open(source);
    }

    const columns = [{
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
    }, {
        title: 'Действия',
        dataIndex: 'sourceId',
        render: (sourceId: bigint, row: Source) =>
            <ButtonGroup>
                <Button
                    onClick={() => handleEdit(row)}
                >
                    <EditOutlined />
                </Button>
                <Popconfirm
                    title="Удаление источника"
                    description="Удаляем?"
                    onConfirm={() => onDeleteSource(row)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button><DeleteOutlined /></Button>
                </Popconfirm>
            </ButtonGroup>
    }];

    const paginationConfig: TablePaginationConfig = {
        total: sourcesData?.totalElements,
        showTotal: (total: number) => 'Всего записей: ' + total,
        showSizeChanger: true, //(() => (projectsData?.totalPages || 0) > 1)(),
        pageSizeOptions: ['1', '10', '25', '50', '100'],
        onChange: (page, pageSize) => {
            const pagination = {page, size: pageSize} as Pagination;
            trigger(pagination);
        }
    }

    return (
        <App>
            <Row>
                <Col>
                <Space align='start'>
                    <Button type="primary" onClick={() => handleEdit(new Source(null, 'Новый источник'))}>+ Создать</Button>
                </Space>
                </Col>
            </Row>
            <Table
                dataSource={sourcesData?.content || []}
                columns={columns}
                loading={isLoading || isDeleting}
                rowKey='projectId'
                pagination={paginationConfig}
            />
            <EditSourceWindow
                ref={editSourceWindowRef}
            />
        </App>
    );
}
import {Row, Dropdown, MenuProps} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useFetchAllProjectsQuery} from "../../services/ProjectsService";
import React, {useEffect, useState} from "react";
import {selectCurrentProject, setCurrentProject} from "../../store/reducers/ProjectSlice";
import {useRequiredTypedSelector} from "../../hooks/useRequiredSelector";
import {useStore} from "react-redux";
import {Pagination} from "../../models/Pagination";

export const Toolbar = () => {
    const {data: projectsData, isLoading} = useFetchAllProjectsQuery();
    const [options, setOptions] = useState([] as any);
    const currentProject = useRequiredTypedSelector(selectCurrentProject).project;
    const { dispatch } = useStore();

    useEffect(() => {
        const items: any = [];
        const projects = projectsData || [];
        projects && projects.map(project => {
            items.push({
                label: project.title,
                key: project.projectId,
            })
        });
        if (projects && projects.length > 0 && !currentProject.projectId) {
            dispatch(setCurrentProject(projects[0]));
        }
        setOptions(items);
    }, [projectsData]);

    const onSelectProject = (event: any) => {
        if (projectsData?.length){
            const selected = projectsData.find(p => p.projectId == event.key);
            if (selected) {
                dispatch(setCurrentProject(selected));
            }
        }
    }

    const items: MenuProps['items'] = options;

    return (
        <Row style={{paddingLeft: 20, paddingTop: 15,}}>
            <Dropdown.Button
                menu={{
                    items,
                    selectable: true,
                    selectedKeys: [],
                    onSelect: onSelectProject,
                }}
                trigger={['click']}
                icon={<DownOutlined/>}
                onClick={onSelectProject}
            >
                Текущий проект: &nbsp;&nbsp;<b>{currentProject.title}</b>
            </Dropdown.Button>
        </Row>
    );
}
import App from "../../App";
import env from "react-dotenv";
import {Button, Image, List, Table, Collapse, Divider, Row, Col, Space} from "antd";
import {DownloadOutlined, DownOutlined, EditOutlined, FileExcelOutlined, FileSearchOutlined} from "@ant-design/icons";
import {Copy} from "../../models/Copy";
import {Image as ImageModel} from "../../models/Image";
import {
    FetchImagesParams, useExportMutation,
    useFetchImagesQuery, useGetAttachedImagesMutation,
    useSearchCopiesMutation
} from "../../services/ImageService";
import {Pagination} from "../../models/Pagination";
import {TablePaginationConfig} from "antd/es/table";
import {useLazyFetchImagesQuery} from "../../services/ImageService";
import {useRequiredTypedSelector} from "../../hooks/useRequiredSelector";
import {selectCurrentProject} from "../../store/reducers/ProjectSlice";
import {Project} from "../../models/Project";
import {Key, ReactNode, SetStateAction, useRef, useState} from "react";
import Link from "antd/lib/typography/Link";
import {EditProjectWindowRef} from "../Projects/EditProjectWindow";
import {EditCopyWindow, EditCopyWindowRef} from "./EditCopyWindow";
import {imagesSlice, updateImageCopy} from "../../store/reducers/ImagesSlice";
import {useStore} from "react-redux";

export const Images = () => {
    const editCopyWindowRef = useRef<EditCopyWindowRef>(null)
    const pagination: Pagination = new Pagination(1, 10000);
    const currentProject = useRequiredTypedSelector(selectCurrentProject).project;
    const params = new FetchImagesParams(pagination, currentProject);
    const {data: imagesData, error, isLoading} = useFetchImagesQuery(params);
    const [searchCopies, {isLoading: isSearching}] = useSearchCopiesMutation();
    const [getAttachedImages, {isLoading: isAttachedLoading}] = useGetAttachedImagesMutation();
    const [exporting, {isLoading: isExporting}] = useExportMutation();
    const [trigger] = useLazyFetchImagesQuery()
    const handleSearchCopies = async (id: number) => {
        await searchCopies(id);
    };

    const handleGetAttachedImages = async (project: Project) => {
        await getAttachedImages(project);
    };

    const handleExport = async (project: Project) => {
        await exporting(project);
    };

    const columns = [{
        title: 'id',
        dataIndex: 'postId',
        key: 'postId',
        width: 50,
    }, {
        title: 'Изображение',
        dataIndex: 'url',
        key: 'url',
        width: 150,
        render: (url: string | undefined) => <Image width='120px' src={url}/>
    }, {
        title: 'Данные аттача',
        dataIndex: 'id',
        key: 'meta',
        width: 800,
        render: (id: any, row: ImageModel) => {
            return (
                <div style={{width: 800}}>
                    <p style={{width: 800}}><b>Страница на сайте</b>: <Link href={row.page} target='_blank'>{row.page}</Link> </p>
                    <p style={{width: 800}}><b>URL изображения</b>: <Link href={row.url} target='_blank'>{row.url}</Link></p>
                    <p style={{width: 800}}><b>Описание</b>: {row.description as ReactNode}</p>
                    <p style={{width: 800}}><b>Подпись</b>: {row.subscribe as ReactNode}</p>
                    <p><b>Изменение на сайте</b>: {row.modified as ReactNode} | <b>Дата загрузки</b>: {row.createdAt as ReactNode} | <b>Последнее обновление</b>: {row.updatedAt as ReactNode}</p>
                </div>
            )}
    },
        Table.EXPAND_COLUMN, {
        title: 'Копии',
        key: 'count',
        dataIndex: 'copies',
        render: ((copies: Copy[], row: ImageModel) =>
            <Button type='link' href={'/copies/' + row.imageId}>{copies.length}</Button>
        )
    }, {
        title: 'Поиск',
        dataIndex: 'imageId',
        render: (imageId: number, row: ImageModel) => <Button onClick={() => handleSearchCopies(imageId)}><FileSearchOutlined /></Button>
    }];

    const paginationConfig: TablePaginationConfig = {
        showTotal: (total: number) => 'Всего записей: ' + total,
        showSizeChanger: true, //(() => (projectsData?.totalPages || 0) > 1)(),
        pageSizeOptions: ['25', '50', '100', '500'],
        onChange: (page, pageSize) => {
            console.log(imagesData);
            const pagination: Pagination = new Pagination(page, pageSize);
            const params1 = new FetchImagesParams(pagination, currentProject);
            trigger(params1);
        }
    }

    const expandTableColumns = [{
        title: 'id',
        dataIndex: 'copyId',
        key: 'copyId',
        width: 50,
    }, {
        title: 'Изображение',
        dataIndex: 'url',
        key: 'url',
        width: 120,
        render: (url: string | undefined, row: Copy) => <Image width='100px' src={row.url}/>
    }, {
        title: 'Данные копии',
        dataIndex: 'id',
        key: 'meta',
        width: 800,
        render: (id: any, row: Copy) => {
            return (
                <div>
                    <p><b>Страница на сайте</b>: <Link href={row.page} target='_blank'>{row.page}</Link> </p>
                    <p><b>URL изображения</b>: <Link href={row.url} target='_blank'>{row.url}</Link></p>
                    <p><b>Дата нахождения</b>: <Link href='' target='_blank'>{row.created_at}</Link></p>
                </div>
            )}
    }, {
        title: 'Обработка',
        dataIndex: 'id',
        key: 'process',
        render: (id: any, row: Copy) => {
            return (
                <div>
                    <p><b>Шифр</b>: <Link href={row.page} target='_blank'>{row.code}</Link> </p>
                    <p><b>Комментарий</b>: <Link href={row.url} target='_blank'>{row.comment}</Link></p>
                    <p><b>Этап обработки</b>: <Link href={row.url} target='_blank'>{row.stage}</Link></p>
                    <p><b>Претензия</b>: <Link href={row.url} target='_blank'>{row.abuse}</Link></p>
                </div>
            )}
    }, {
        title: '',
        dataIndex: 'copyId',
        render: (copyId: number, row: Copy) => <Button onClick={() => handleEditCopy(row.imageId, row)}><EditOutlined /></Button>
    }];

    const handleEditCopy = (imageId: bigint, copy: Copy) => {
        editCopyWindowRef.current?.open(copy);
    }

    const exportUrl = env.API_URL + "/export?project_id=" + currentProject.projectId;

    return (
        <App>
            <Row>
                <Col>
                    <Space align='start'>
                        <Button disabled={isAttachedLoading || isExporting} type="primary" onClick={() => handleGetAttachedImages(currentProject)}><DownloadOutlined />&nbsp;Загрузить из источника</Button>
                        <Button disabled={isAttachedLoading || isExporting} type="primary" href={exportUrl}><FileExcelOutlined />&nbsp;Экспорт</Button>
                    </Space>
                </Col>
            </Row>
            <Table
                dataSource={imagesData?.content || []}
                columns={columns}
                loading={isLoading || isSearching || isAttachedLoading}
                pagination={paginationConfig}
                expandable={{
                    expandedRowRender: (record) =>
                        <Table
                            dataSource={record.copies}
                            columns={expandTableColumns}
                        />,
                    rowExpandable: (record) => record.copies.length > 0,
                }}
                rowKey='imageId'
            />
            <EditCopyWindow
                ref={editCopyWindowRef}
            />
        </App>
    );
}